<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px;">
      <div class="query-c">
        <Button type="primary" size="small" @click="showInfoDialog">新增</Button>
      </div>
      <br>
      <Table max-height="670" border stripe :columns="columns" :data="data">
        <template #image_url="{row,index}">
          <div v-if="validImageOrVideo(row.image_url)">
            <video :src="`https://${row.image_url}`"  width="200px" height="100px" controls></video>
          </div>
          <div v-else>
            <img :src="`https://${row.image_url}`" :fit="'cover'" width="200px" height="100px"  />
          </div>
        </template>
        <template #createdAt="{row,index}">
          <div>
            {{timeFormatter(row.createdAt,'{year}-{month}-{day} {hour}:{minute}:{second}')}}
          </div>
        </template>
        <template #action="{ row, index }">
          <div>
            <Button class="operate-btn" type="primary" size="small"
              @click="showInfoDialog({ row, index })">编辑</Button>
            <Button class="operate-btn" type="error" size="small"
              @click="deleteItem({ row, index })">删除</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="total" :page-size="pageSize" show-elevator @on-change="changePage" />
    </div>

    <Modal v-model="bannerVisible" title="轮播图" @on-ok="updateHomePageSlider" @on-cancel="cancel">
      <Form ref="bannerInfo" :model="bannerInfo" :rules="ruleInline">
        <FormItem prop="companyName">
          <upload @uploadSuccess="uploadSuccess" @uploadRemove="uploadRemove" :fileList="fileList"></upload>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import { getHomePageSliderList, updateHomePageSlider, createHomePageSlider, deleteHomePageSliderById } from '@/api'
import { formatterPrice, timeFormatter } from '@/utils'
import upload from '@/components/upload'

export default {
    name: 'banner',
    components: {
        upload,
    },
    data() {
        return {
            columns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '图片',
                    slot: 'image_url',
                },
                {
                    title: '创建时间',
                    width: 200,
                    slot: 'createdAt',
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150,
                    align: 'center',
                },
            ],
            data: [],
            total: 0,
            ruleInline: {},
            fileList: [],
            bannerInfo: {
                image_url: null,
            },
            bannerVisible: false,
            page: 1,
            pageSize: 10,
            phone: '',
            name: '',
            date: [new Date(new Date().getTime() - 3600000 * 24 * 30), new Date()],
        }
    },
    mounted() {
        this.getHomePageSliderList()
    },
    methods: {
        validImageOrVideo(url) {
            return !!url.match(/.mp4$/)
        },
        timeFormatter(date, fmt) {
            return timeFormatter(date, fmt)
        },
        formatterPrice(price) {
            return formatterPrice(price)
        },
        changeDate(date) {
            console.log(date)
            this.date = date
        },
        changePage(page) {
            console.log(page)
            this.page = page
            this.getHomePageSliderList()
        },
        async getHomePageSliderList() {
            const param = {
                page: this.page,
                pageSize: this.pageSize,
                // startDate: this.date[0],
                // endDate: this.date[1],
                // name: this.name,
                // phone: this.phone,
            }
            const { success, data: { data, total } } = await getHomePageSliderList(param)
            if (success) {
                this.total = total
                this.data = data.map((i, idx) => {
                    i.index = (this.page - 1) * this.pageSize + idx + 1
                    return i
                })
            }
        },
        showInfoDialog({ row }) {
            this.bannerVisible = true
            this.bannerInfo = row ? JSON.parse(JSON.stringify(row)) : {
                image_url: null,
            }
            this.fileList = this.bannerInfo?.image_url ? [{ status: 'finished', ...this.bannerInfo }] : []
            console.log(this.fileList)
        },
        cancel() {
            this.bannerVisible = false
            this.bannerInfo = {
                image_url: null,
            }
        },
        deleteItem({ row, index }) {
            console.log(index)
            console.log(row)
            console.log(this.$Modal)
            this.$Modal.confirm({
                title: '确认删除',
                content: '<p>确认删除</p>',
                onOk: async () => {
                    const param = {
                        id: row.id,
                    }
                    const { success, data } = await deleteHomePageSliderById(param)
                    if (success) {
                        console.log(data)
                        this.getHomePageSliderList()
                    }
                    this.$Message.info('Clicked ok')
                },
                onCancel: () => {
                    this.bannerInfo = {
                        image_url: null,
                    }
                },
            })
        },
        async updateHomePageSlider() {
            console.log(this.bannerInfo)
            const param = {
                image_url: this.bannerInfo.image_url,
            }
            if (this.bannerInfo.id) {
                param.id = this.bannerInfo.id
            }
            const { success, data } = this.bannerInfo.id ? await updateHomePageSlider(param) : await createHomePageSlider(param)
            if (success) {
                console.log(data)
                this.getHomePageSliderList()
            }
            this.fileList = []
        },
        uploadSuccess(list) {
            console.log(list)
            console.log(this.bannerInfo)
            this.$set(this.bannerInfo, 'image_url', list[0].image_url)
        },
        uploadRemove() {
            this.fileList = []
        },
    },
}
</script>

<style scoped>
.operate-btn {
  margin-bottom: 10px;

}


.operate-btn+.operate-btn {
  margin-left: 5px;
}
</style>