<template>
  <div>
    <div class="demo-upload-list" v-for="(item, idx) in uploadList" :key="`${item.id}-${idx}`">
      <template v-if="item.status === 'finished'">
        <video v-if="validImageOrVideo(item.image_url)" :src="'https://' + item.image_url" fit="cover" width="100%" height="100%" ></video>
        <img v-else :src="'https://' + item.image_url" fit="cover" width="100%" height="100%" />
        <div class="demo-upload-list-cover">
          <!-- <Icon type="ios-eye-outline" @click="handleView(item.name)"></Icon> -->
          <Icon type="ios-trash-outline" @click="handleRemove(item)"></Icon>
        </div>
      </template>
      <template v-else>
        <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
      </template>
    </div>
    <Upload v-show="uploadList?.length < limit" ref="upload" :show-upload-list="false" :default-file-list="defaultList"
      :on-success="handleSuccess" :format="['jpg', 'jpeg', 'png','mp4']" :max-size="204800" :on-format-error="handleFormatError"
      :on-exceeded-size="handleMaxSize" :before-upload="handleBeforeUpload" type="drag" :action="uploadApi"
      :headers="uploadHeaders" :on-error="handleUploadError" style="display: inline-block;width:58px;">
      <div style="width: 58px;height:58px;line-height: 58px;">
        <Icon type="ios-camera" size="20"></Icon>
      </div>
    </Upload>
    <!-- <ImagePreview v-model="visible" :preview-list="['https://file.iviewui.com/images/' + imgName]" /> -->
  </div>
</template>
<script>
import { Upload } from 'view-design'
import { showLoading, closeLoading } from '@/utils/loading'

export default {
    components: {
        Upload,
    },
    props: {
        fileList: {
            type: Array,
            default: () => [],
        },
        limit: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            defaultList: [],
            imgName: '',
            visible: false,
            uploadList: [],
            uploadHeaders: {
                Authorization: localStorage.getItem('token'),
            },
            uploadApi: `${process.env.VUE_APP_BASE_URL}admin/upload_image`,
        }
    },
    watch: {
        fileList() {
            this.uploadList = this.fileList
        },
    },
    methods: {
        validImageOrVideo(url) {
            return !!url.match(/.mp4$/)
        },
        handleView(name) {
            this.imgName = name
            this.visible = true
        },
        handleRemove(file) {
            console.log(file)
            this.$refs.upload.fileList.splice(this.$refs.upload.fileList.indexOf(file), 1)
            this.$emit('uploadRemove', file)
        },
        handleSuccess({ data }) {
            closeLoading()
            this.uploadList.push({
                image_url: data.oss_url,
                status: 'finished',
            })
            this.$emit('uploadSuccess', this.uploadList)
        },
        handleFormatError(file) {
            closeLoading()
            this.$Notice.warning({
                title: '文件格式不正确',
                desc: '文件 ' + file.name + ' 格式不正确，请上传 jpg、jpeg、png 或 mp4 ',
            })
        },
        handleUploadError() {
            closeLoading()
            this.$Notice.error({
                title: '上传失败',
                desc: '文件上传失败,请检查网络后重试.',
            })
        },
        handleMaxSize(file) {
            closeLoading()
            this.$Notice.warning({
                title: '超出文件大小限制',
                desc: '文件  ' + file.name + ' 太大，不能超过 200M.',
            })
        },
        handleBeforeUpload() {
            showLoading()
            const check = this.uploadList.length < this.limit
            if (!check) {
                this.$Notice.warning({
                    title: 'Up to five pictures can be uploaded.',
                })
            }
            return check
        },
    },
    mounted() {
    // this.uploadList = this.$refs.upload.fileList
    },
}
</script>
<style>
.demo-upload-list {
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}

.demo-upload-list img {
  width: 100%;
  height: 100%;
}

.demo-upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .6);
}

.demo-upload-list:hover .demo-upload-list-cover {
  display: block;
}

.demo-upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
